<template>
  <div :ref="modalId" class="modal fade" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bold text-center">
          {{title}}
        </div>
        <div class="modal-body row text-break">
          <i class="col-4 fas fa-exclamation fa-5x align-self-center text-center"></i>
          <div id="modalHintContent" class="col-8 align-self-center text-bold text-secondary">
            <div>{{content}}</div>
            <div class="text-primary mt-2">發布單位</div>
            {{data.identityVerify.publisher}}
            <div class="text-primary mt-2">聯絡人姓名</div>
            <div>{{data.identityVerify.contactName}}</div>
            <div class="text-primary mt-2">聯絡人email</div>
            <div>{{data.identityVerify.contactEmail}}</div>
            <div v-if="data.status == Const().APPLICATION_STATUS_REJECTED">
              <div class="form-group mt-2">
                <label for="rejectReason">審核拒絕原因(必填)</label>
                <textarea class="form-control" id="rejectReason"
                          cols="50" rows="5" style="resize: none;"
                          v-model="data.rejectReason"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                  @click="hide">取消</button>
          <button type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  :disabled="checkInvalid()"
                  @click="confirmUpdate">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalList from "@/const/modal";
import { mapMutations } from "vuex";
import Const from "@/const";
import {utilMixin} from "@/mixins/utilMixin";

export default {
  name: "UpdateIdentityVerifyModal",
  mixins: [utilMixin],
  data(){
    return {
      modalId: ModalList.UPDATE_IDENTITY_VERIFY_MODAL,
      title: '',
      content: '',
      data: {
        identityVerify: {},
        status: '',
        rejectReason: '',
        updateFunc: () => {}
      }
    }
  },
  methods: {
    ...mapMutations({
      showModal: 'showModal'
    }),
    show(data){
      data = data ? data : {}
      this.data.identityVerify = data.identityVerify ? data.identityVerify : {}
      this.data.status = data.status ? data.status : ''
      this.data.rejectReason = ''
      this.data.updateFunc = data.updateFunc ? data.updateFunc : null
      if(this.data.status == Const.APPLICATION_STATUS_APPROVED){
        this.title = "確認通過使用者的身份驗證？"
        this.content = "通過身份驗證後，使用者將可以使用發文功能。"
      }else if (this.data.status == Const.APPLICATION_STATUS_REJECTED) {
        this.title = "確認駁回使用者的身份驗證？"
        this.content = "駁回身份驗證後，使用者需重新送出驗證申請。"
      }
      $(this.$refs[this.modalId]).modal('show')
    },
    hide(){
      $(this.$refs[this.modalId]).modal('hide')
    },
    checkInvalid(){
      if(this.data.status == Const.APPLICATION_STATUS_APPROVED){
        return false
      }else {
        return this.data.rejectReason.trim().length == 0;
      }
    },
    confirmUpdate(){
      this.data.updateFunc({
        id: this.data.identityVerify.id,
        status: this.data.status,
        rejectReason: this.data.rejectReason
      })
    },
  }
}
</script>

<style scoped>

</style>
